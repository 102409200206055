<template>
  <v-form
    v-model="valid"
  >
    <v-expansion-panels
      mandatory
      flat
      v-if="offer"
    >
      <v-expansion-panel>
        <v-expansion-panel-header> {{ $t('information') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense>
            <v-col
              cols="12"
            >
              <v-text-field
                :label="$t('title')"
                type="text"
                required
                v-model="offer.title"
                :rules="[rules.required]"
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                :label="$t('discountPercent')"
                required
                type="number"
                v-model="offer.discountPercent"
                :rules="[rules.required, rules.percentageValue]"
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-select
                :label="$t('type')"
                v-model="offer.type"
                :items="['order', 'lineItem']"
                item-value="key"
                item-text="label"
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-checkbox
                dense
                :label="$t('compatibleWithOtherOffers')"
                filled
                v-model="offer.compatibleWithOtherOffers"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{ this.$t('itemLimitations') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-col
            cols="12"
            class="pt-0"
            :key="forceRenderKey"
          >
            <!--Limitation Items-->
            <v-card
              outlined
              class="my-3"
              style="border-color: rgba(0, 0, 0, 0.87)"
            >
              <v-card-subtitle v-if="offer.items.length > 0">
                {{ $t('offerLimitedTo') }}
              </v-card-subtitle>
              <v-card-text v-if="offer.items.length > 0">
                <v-btn 
                  v-for="item in offer.items"
                  :key="offer.items[item]"
                  depressed
                  class="ma-1 pr-3"
                  @click="itemDialog(item.uuid)"
                  :ripple="false"
                  label
                >
                  {{ item.title }}
                  <v-divider
                    vertical
                    class="mx-2"
                  />
                  <v-btn
                    icon
                    @click="removeItem(item.uuid)"
                    @click.stop=""
                    :ripple="false"
                  >
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-btn>
              </v-card-text>
              <v-card-text
                v-else
                class="text-center"
              >
                <span> {{ $t('noItemLimitations') }}</span>
              </v-card-text>
            </v-card>
            <!--Add Item-->

            <v-row>
              <v-col
                cols="10"
              >
                <v-autocomplete
                  v-model="selectedItem"
                  :items="items"
                  item-text="title"
                  return-object
                  outlined 
                  hide-details
                  style="margin-right: 15px; max-width: 460px"
                  :label="$t('addItem')"
                >
                  <template slot="append">
                    <v-item-group
                      class="v-btn-toggle mb-4"
                      outlined
                      variant="outlined"
                    >
                      <v-btn
                        @click="addItem(selectedItem)"
                        outlined
                        @mousedown.stop=""
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                      <v-btn
                        @click="itemDialog(selectedItem.uuid)"
                        outlined
                        @mousedown.stop=""
                      >
                        {{ $t('details') }}
                      </v-btn>
                    </v-item-group>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!--Limitation Categories-->
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{ this.$t('categoryLimitations') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-col
            cols="12"
            class="pt-0"
          >
            <v-alert
              type="warning"
              class="text-center"
            >
              {{ $t('currentlyNotImplemented') }}
            </v-alert>
            <!--TODO?-->
            <!-- <v-card
              outlined
              class="my-3"
            >
              <v-card-subtitle>{{ $t('offerLimitedTo') }}</v-card-subtitle>
              <v-card-text class="text-center pb-6">
                <span style="font-size: x-large;">{{ $t('notImplemented') }}</span>
                <v-btn 
                  v-for="category in value.categories"
                  :key="value.categories[cateogory]"
                  depressed
                  class="ma-1 pr-3"
                  @click="itemDialog(category.uuid)"
                  :ripple="false"
                  label
                >
                  {{ category.title }}
                  <v-divider
                    vertical
                    class="mx-2"
                  />
                  <v-btn
                    icon
                    @click="removeCategory(category)"
                    @click.stop=""
                    :ripple="false"
                  >
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-btn>
              </v-card-text>
            </v-card>
            <v-row>
              <v-col
                cols="10"
              >
                <v-autocomplete
                  item-text="title"
                  return-object
                  outlined 
                  hide-details
                  style="margin-right: 15px; max-width: 460px"
                  disabled
                >
                  <template slot="append">
                    <v-item-group
                      class="v-btn-toggle mb-4"
                      outlined
                      variant="outlined"
                    >
                      <v-btn
                        outlined
                        disabled
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                      <v-btn
                        outlined
                        disabled
                      >
                        {{ $t('details') }}
                      </v-btn>
                    </v-item-group>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row> -->
          </v-col>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{ this.$t('conditions') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content :key="forceRenderKey">
          <OfferConditionsList 
            :offer-conditions="offer.conditions"
            @removeCondition="removeCondition"
            @addCondition="addCondition"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-form>
</template>
  
  <script>
  export default {
    name: 'OfferForm', 
    props: {
        value: {
            type: Object, 
            default: () => {}
        }, 
        triggerUpdate: {
            type: Boolean, 
            default: false
        },
    },
    components: {
      'OfferConditionsList': () => import('@/components/offers/OfferConditionsList')
    },
    data() {
      return {
        valid: false,
        selectedItem: null, 
        forceRenderKey: 0
      }
    },
    computed: {
      offer: {
        get() { 
          return this.value 
        },
        set(value) { 
          this.$emit('input', value) 
        }
      },
      rules() {
        return this.$store.getters.rules
      }, 
      items() {
        return this.$store.state.items.map(item => {
          return {
            uuid: item.uuid,
            title: item.title
          }
        })
      }, 
      percentages() {
          const percentages = []
          for(let i = 0; i < 100; i++) {
              percentages.push(i + 1)
          }
          return percentages
      }
    }, 
    methods: {
      removeItem(itemUUID) {
        if(this.offer.items.length > 0) {
          const targetIndex = this.offer.items.findIndex(item => item.uuid === itemUUID)
          this.offer.items.splice(targetIndex,1)
        }
        this.forceRenderKey++
      }, 
      itemDialog(itemUUID) {
        this.$store.commit('updateContextOverlay', {
          'component': 'components/item/ItemProfile',
          'props': {
            'uuid':itemUUID
          } 
        })
      },
      addItem(item) {
        if(!this.offer.items.some(li => li.uuid === item.uuid)) {
          this.offer.items.push(item)
          this.forceRenderKey++
        }
      }, 
      addCondition() {
        if(!this.offer.conditions || !Array.isArray(this.offer.conditions)) {
          this.offer.conditions = []
        }
        this.offer.conditions.push({
          property: null, 
          operator: null, 
          floatValue: null
        })
        this.forceRenderKey++
      }, 
      removeCondition(index) {
        this.offer.conditions.splice(index, 1)
        this.forceRenderKey++
      }
    },  
    watch: {
      value(newValue, oldValue) {
        if(newValue && newValue.uuid && oldValue && oldValue.uuid && newValue.uuid !== oldValue.uuid) {
          this.offer = JSON.parse(JSON.stringify(newValue))
        }
      },
      triggerUpdate(value) {  
        if(value === true) {
          //Offer query returns offer.items as array of objects, updateOffer accepts them as array of uuid strings
          if(this.offer.items && Array.isArray(this.offer.items)) {
            const itemIds = this.offer.items.map(item => item.uuid)
            this.offer.items = itemIds
          }

          if(this.offer.conditions && Array.isArray(this.offer.conditions)) {
            this.offer.conditions.map(condition => condition.floatValue = parseFloat(condition.floatValue))
          }

          return this.$store.dispatch('updateOffer', this.offer).then(res => {
            if(res) {
              this.$store.commit('updateActionSuccess', {
                message: this.$t('success'), 
                subMessage: this.$t('successfullyUpdatedOffer')
              })
              this.$emit('updateProfile', res)
            } else {
              this.$store.commit('updateActionError', {
                message: this.$t('error'), 
                subMessage: this.$t('somethingWentWrong')
              })
            }
          }).catch(() => {
            this.$store.commit('updateActionError', {
              message: this.$t('error'), 
              subMessage: this.$t('somethingWentWrong')
            })
          }).finally(() => {
            this.$emit('finishedUpdating')
          })
        }
      }, 
      valid(value) {
        this.$emit('valid', value)
      }
    }, 
    mounted() {
      this.offer = JSON.parse(JSON.stringify(this.value))
    } 
  }
  </script>
  
  <style scoped>
        .v-expansion-panel {
        border: thin solid rgba(0, 0, 0, 0.12);
        border-radius: 8px
      }
  
      .v-expansion-panels {
        border-radius: 8px
      }

      .v-label {
        margin-top: 14px
      }

      .v-label--active {
        margin-top: 0px;
      }
  </style>